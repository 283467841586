<template>
  <BaseCard
    title="datos del job"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <JobProfileForm
      ref="job-profile-form"
      v-model="job"
    />
    <hr class="mx-2">
    <div class="px-2 pt-2 font-weight-bold text-uppercase">
      condiciones economicas del job
    </div>
    <JobEconomicConditionsForm
      ref="job-economic-conditions-form"
      v-model="job"
    />
  </BaseCard>
</template>

<script>
import ApiRestService from '@/api/base-api'
import JobsApi from '@/api/jobs-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import JobProfileForm from '@/components/jobs/form/JobProfileForm.vue'
import JobEconomicConditionsForm from '@/components/jobs/form/JobEconomicConditionsForm.vue'

export default {
  components: { JobEconomicConditionsForm, JobProfileForm, BaseCard },
  data() {
    return {
      loading: false,
      job: {},
    }
  },
  computed: {
    clientId() {
      return this.$route.query['client-id'] || null
    },
    projectId() {
      return this.$route.query['project-id'] || null
    },
    jobProfileForm() {
      return this.$refs['job-profile-form']
    },
    jobEconomicConditionsForm() {
      return this.$refs['job-economic-conditions-form']
    },
  },
  mounted() {
    this.loadClient()
    this.loadProject()
  },
  methods: {
    async loadClient() {
      if (!this.clientId) {
        return
      }

      this.loading = true
      try {
        const response = await ApiRestService.get(this.clientId, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
        this.job = { ...this.job, client: response.data }
      } finally {
        this.loading = false
      }
    },
    async loadProject() {
      if (!this.projectId) {
        return
      }

      this.loading = true
      try {
        const response = await ApiRestService.get(this.projectId, this.$data.$constants.RESOURCES.RESOURCE_PROJECTS)
        this.job = { ...this.job, project: response.data }
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const profileValid = await this.jobProfileForm.validateForm()
      if (!profileValid) {
        return
      }

      this.loading = true
      try {
        const { contract } = this.jobProfileForm.getContractData()
        const { documentation } = this.jobEconomicConditionsForm.getDocumentationData()
        const response = await JobsApi.create({ ...this.job, contract, documentation })
        const newJob = response.data
        await this.$router.replace({ name: 'viewJob', params: { id: newJob.id } })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
